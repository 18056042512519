import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import { toast } from 'react-toastify'
import { useLazyQuery, useMutation } from '@apollo/client'

import UserContext from 'context/user/UserContext'
import { CHECK_IF_MEETING_EXISTS, CREATE_MEETING } from 'services/graphql'
import screenLogoMark from 'images/png/Quiklearn_Logomark.png'
import {
  SectionOne,
  SectionOneLeft,
  SectionOneText,
  SectionOneRight,
  ButtonContainer,
  ScreenImage,
} from './LandingSectionComponents'
import 'react-toastify/dist/ReactToastify.css'
import NewMeetingButton from 'components/Meeting/NewMeetingButton'
import JoinMeetingForm from 'components/Meeting/JoinMeetingForm/JoinMeetingForm'
import NewMeetingOptionsModal from 'components/Meeting/NewMeetingOptionsModal'
import ShareLinkModal from 'components/Meeting/ShareLinkModal'
import YouTubeModal from 'components/common/YouTubeModal'

const LandingSection = () => {
  const { t } = useTranslation('landingSection')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)
  const CUSTOM_MEETING_QUERY_STRING = '?meeting='
  const VALID_MEETING_ID_LENGTH = 12

  const { user } = useContext(UserContext)
  const [isJoinLinkTextValid, setIsJoinLinkTextValid] = useState(true)
  const [isMeetingOptionsModalOpen, setIsMeetingOptionsModalOpen] = useState(
    false,
  )
  const [isShareLinkModalOpen, setIsShareLinkModalOpen] = useState(false)
  const [selectedModalOption, setSelectedModalOption] = useState(0)

  const [
    checkIfMeetingExists,
    {
      data: checkIfMeetingExistsData,
      loading: checkIfMeetingExistsLoading,
      error: checkIfMeetingExistsError,
    },
  ] = useLazyQuery(CHECK_IF_MEETING_EXISTS)

  const [
    createMeeting,
    {
      data: createMeetingData,
      loading: createMeetingLoading,
      error: createMeetingError,
    },
  ] = useMutation(CREATE_MEETING)

  const notifyInvalidMeeting = useCallback(
    () => toast.dark(t('couldntFindMeeting')),
    [t],
  )
  const notifyUnauthUser = useCallback(() => toast.dark(t('pleaseSignIn')), [t])
  const notifyError = useCallback(() => toast.error(t('error')), [t])

  // redirect the user to 3Dmeet URL if valid, otherwise notify error using toast
  useEffect(() => {
    if (
      checkIfMeetingExistsData &&
      checkIfMeetingExistsData.checkIfMeetingExists &&
      checkIfMeetingExistsData.checkIfMeetingExists.id
    ) {
      setIsJoinLinkTextValid(true)
      navigate(
        process.env.GATSBY_SITE_INVITE_PATH +
          checkIfMeetingExistsData.checkIfMeetingExists.id,
      )
    } else if (
      checkIfMeetingExistsData &&
      !checkIfMeetingExistsData.checkIfMeetingExists &&
      user
    ) {
      setIsJoinLinkTextValid(false)
      notifyInvalidMeeting()
    } else if (
      checkIfMeetingExistsError ||
      (checkIfMeetingExistsData &&
        !checkIfMeetingExistsData.checkIfMeetingExists &&
        !user)
    ) {
      navigate(process.env.GATSBY_SITE_WHOOPS_PATH)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [checkIfMeetingExistsData, user, checkIfMeetingExistsError])

  const onClickNewMeeting = () => {
    // redirect user to login if unauthenticated
    if (!user) {
      navigate(process.env.GATSBY_SITE_NEW_MEETING_PATH)
    } else {
      setIsMeetingOptionsModalOpen(true)
    }
  }

  const onClickJoinMeeting = async (meetingIdParam) => {
    if (!checkIfMeetingExistsLoading && !createMeetingLoading) {
      let meetingId = meetingIdParam

      // grab only the meetind id from input
      meetingId = meetingIdParam.slice(-VALID_MEETING_ID_LENGTH)

      await checkIfMeetingExists({
        variables: { meetingId },
      })

      // show toast for invalid meeting without recieving error on network request
      if (!isJoinLinkTextValid) {
        notifyInvalidMeeting()
      }
    }
  }

  // redirect the user to new 3Dmeet meeting if valid, otherwise notify error using toast
  useEffect(() => {
    if (
      createMeetingData &&
      createMeetingData.createMeeting &&
      selectedModalOption === 2 &&
      typeof window !== 'undefined'
    ) {
      window.location.assign(createMeetingData.createMeeting.hostLink)
    }
  }, [createMeetingData, selectedModalOption])

  // redirect user to custom meeting page after meeting has been created
  useEffect(() => {
    if (
      createMeetingData &&
      createMeetingData.createMeeting &&
      selectedModalOption === 3 &&
      typeof window !== 'undefined'
    ) {
      const meetingId = createMeetingData.createMeeting.id

      window.location.assign(
        process.env.GATSBY_SITE_BASE_URL +
          process.env.GATSBY_SITE_CUSTOM_MEETING_PATH +
          CUSTOM_MEETING_QUERY_STRING +
          meetingId,
      )
    }
  }, [createMeetingData, selectedModalOption])

  useEffect(() => {
    if (createMeetingError || checkIfMeetingExistsError) {
      notifyError()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [createMeetingError, checkIfMeetingExistsError])

  const onStartInstantMeeting = async () => {
    if (
      user &&
      user.uid &&
      !checkIfMeetingExistsLoading &&
      !createMeetingLoading
    ) {
      await createMeeting()
    } else {
      notifyUnauthUser()
    }
  }

  return (
    <SectionOne>
      {isModalOpen ? (
        <YouTubeModal
          onClick={toggleModal}
          youtubeVideoId={t('youtubeIdHawaii')}
          title={t('youtubeTitleHawaii')}
        />
      ) : null}
      {isShareLinkModalOpen ? (
        <ShareLinkModal
          setIsOpen={(isOpen) => setIsShareLinkModalOpen(isOpen)}
          isOpen={isShareLinkModalOpen}
        />
      ) : null}
      <SectionOneLeft>
        <SectionOneText>
          <h1>{t('heading1')}</h1>
          <h1>{t('heading2')}</h1>
          <p>
            {t('paragraph1')} <br />
            <a
              onClick={() => toggleModal()}
              role="button"
              tabIndex={0}
              href="#/"
            >
              {t('paragraph2')}
            </a>
          </p>
        </SectionOneText>
        <ButtonContainer>
          {isMeetingOptionsModalOpen ? (
            <NewMeetingOptionsModal
              setIsMeetingOptionsModalOpen={setIsMeetingOptionsModalOpen}
              setIsShareLinkModalOpen={setIsShareLinkModalOpen}
              setSelectedModalOption={setSelectedModalOption}
              selectedModalOption={selectedModalOption}
              onStartInstantMeeting={onStartInstantMeeting}
              createMeetingLoading={createMeetingLoading}
            />
          ) : null}
          <NewMeetingButton onClickNewMeeting={onClickNewMeeting} />
          <JoinMeetingForm
            onClickJoinMeeting={onClickJoinMeeting}
            setIsJoinLinkTextValid={setIsJoinLinkTextValid}
            checkIfMeetingExistsLoading={checkIfMeetingExistsLoading}
          />
        </ButtonContainer>
      </SectionOneLeft>
      <SectionOneRight>
        <ScreenImage src={screenLogoMark} alt={t('altImageScreen')} />
      </SectionOneRight>
    </SectionOne>
  )
}

export default LandingSection
